<template>
	<div class='cu-main'>
		<div class='nav-box solid-bottom solid-top'>
			<el-scrollbar>
				<div class='scrollbar-flex-content'>
					<p class='scrollbar-demo-item'
					   :class="page === '/home' ?'active':''"
					   @click.stop="clickSelect('/home')">首页</p>
					<p v-for='(item,index) in pageList' :key='index'
					   class='scrollbar-demo-item'
					   @click.stop='clickSelect(item.path)'
					   :class="item.path === page ?'active':''">
						{{ item.title }}
						<i class='iconfont icon-guanbi1' v-if='item.close && item.path === page'
						   @click.stop='clickClose(item)'></i>
					</p>
				</div>
			</el-scrollbar>
		</div>
		<div class='main-box'>
			<router-view v-slot='{ Component }'>
				<keep-alive>
					<component
						:is='Component'
						v-if='$route.meta.keepAlive'
						:key='$route.name'
					/>
				</keep-alive>
				<component
					:is='Component'
					v-if='!$route.meta.keepAlive'
					:key='$route.name'
				/>
			</router-view>
		</div>
	</div>
</template>

<script>
import { closePage } from '@/utils/menu';

export default {
	name: 'cu-main',
	computed: {
		pageList() {
			return this.$store.getters['basic/get_pageList'];
		},
		page() {
			return this.$store.getters['basic/get_page'];
		}
	},
	methods: {
		clickClose(row) {
			closePage(row);
		},
		clickSelect(path) {
			this.$router.push({ path: path });
		}
	}
};
</script>

<style lang='scss' scoped>
@import "../../assets/layout";

.cu-main {
	width: calc(100% - #{$aside-width});
	height: calc(100% - #{$header-height});
	position: fixed;
	z-index: 1000;
	top: $header-height;
	left: $aside-width;
	background-color: #f8f8f8;

	.nav-box {
		height: $nav-box-height;
		display: flex;
		border-bottom: 1px solid #d8dce5;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
		box-sizing: border-box;
	}

	.main-box {
		padding: 10px;
		height: calc(100% - #{$nav-box-height});
		box-sizing: border-box;
	}
}

.scrollbar-flex-content {
	display: flex;
	padding-top: 4px;
}

.scrollbar-demo-item {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 8px;
	padding-right: 8px;
	height: 24px;
	margin-left: 10px;
	text-align: center;
	border-radius: 2px;
	background-color: #dcdfe6;
	font-size: 13px;
	cursor: pointer;
	color: #000000;

	.iconfont {
		font-size: 16px;
		margin-left: 5px;
	}
}

.scrollbar-demo-item + .scrollbar-demo-item {
	margin-left: 5px !important;
}

.scrollbar-demo-item.active {
	background-color: $aside-bg;
	color: #f8f8f8;

	.iconfont:hover {
		color: #f37b1d;
	}
}
</style>