import { ref } from 'vue';
import store from '@/store/index.js';
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';

import CryptoJS from './crypto-js.min';

const loadingCount = ref(0);
const loading = ref(null);

export const showLoading = () => {
	loading.value = ElLoading.service({
		lock: true,
		text: '处理中，请稍后',
		background: 'rgba(0, 0, 0, 0.7)'
	});
};

export const closeLoading = () => {
	if (loading.value) {
		loading.value.close();
	}
};

export const addLoading = async () => {
	loadingCount.value++;
	await store.dispatch('basic/up_loading', true);
};

export const isCloseLoading = async () => {
	loadingCount.value--;
	if (loadingCount.value === 0) {
		await store.dispatch('basic/up_loading', false);
	}
};

export const listToLevel = ({ list = [], keys = '', pid = '0' }) => {
	if (list.length === 0) {
		return [];
	}
	let res = [];
	list.forEach(item => {
		if (item['parent_id'] == pid) {
			item['children'] = listToLevel({ list, keys, pid: item[keys] });
			res.push(item);
		}
	});
	return res;
};

export const err_message = msg => {
	ElMessage.error(msg);
};

export const suc_message = () => {
	ElMessage.success('成功');
};

export const confirm = msg => {
	return new Promise((resolve, reject) => {
		ElMessageBox.confirm(msg ?? '确定删除吗，删除后无法恢复！', '系统提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				resolve(true);
			})
			.catch(() => {
				resolve(false);
			});
	});
};

export const err_alert = msg => {
	ElMessageBox.alert(msg, '系统提示', {
		confirmButtonText: '知道！',
		type: 'warning'
	})
		.then(r => r)
		.catch(e => e);
};

export const err_repeat = () => {
	ElMessageBox.alert('请求正在处理，请勿重复点击', '系统提示', {
		confirmButtonText: '知道！',
		type: 'warning'
	})
		.then(r => r)
		.catch(e => e);
};

//数据加密
export const encrypt = data => {
	return CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse('daBmT2PBEJVrZ8iR'), {
		mode: CryptoJS.mode.CBC,
		iv: CryptoJS.enc.Utf8.parse('bGHmtkhefz7sI4k8'),
		padding: CryptoJS.pad.Pkcs7
	}).toString();
};
//数据解密
export const decrypt = data => {
	return CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse('daBmT2PBEJVrZ8iR'), {
		mode: CryptoJS.mode.CBC,
		iv: CryptoJS.enc.Utf8.parse('bGHmtkhefz7sI4k8'),
		padding: CryptoJS.pad.Pkcs7
	}).toString(CryptoJS.enc.Utf8);
};

export const exportFindItem = (find, data, key) => {
	let list = [];
	find.forEach(row => {
		let obj = data.find(item => item[key] === row);
		if (obj) {
			list.push(obj);
		}
	});
	return JSON.parse(JSON.stringify(list));
};

/**
 * 提炼导出的各字段标题和对应的key，返回对象包包含值：title_array,keys_array,number_array
 * @param data 数据源
 * @param title_key 数据源中标题key，如 label:订单号
 * @param valueKey 数据源中值key，如 prop:order_no
 * @return object
 * */
export const getExportTitleAndValueKeys = (data, title_key, valueKey) => {
	let title_array = [];
	let keys_array = [];
	let number_array = [];
	data.forEach(item => {
		title_array.push(item[title_key]);
		keys_array.push(item[valueKey]);
		if (item['is_number']) {
			number_array.push(item[valueKey]);
		}
	});
	return {
		title_array,
		keys_array,
		number_array
	};
};

/**
 * 获取当前时间
 * @param link 连接符，默认为 -
 * @param is_time 返回是否带有时分秒，默认true
 * */
export const getCurrentTime = (link, is_time) => {
	let links = link ?? '-';
	let is_times = is_time ?? true;
	let date = new Date();
	let str = date.getFullYear() + links + (date.getMonth() + 1) + links + date.getDate();
	if (is_times) {
		str = str + links + date.toLocaleTimeString();
	}
	return str;
};

const replaceString = (regx, val) => {
	return val.replace(regx, '');
};

export const replaceNumber = (val, len) => {
	let str = replaceString(/[^0-9.]/g, val);
	if (/\./.test(str)) {
		let array = str.split('.');
		str = array[0];
		if (array[1].length > 2) {
			str = str + '.' + array[1].substr(0, len);
		} else {
			str = str + '.' + array[1];
		}
	}
	return str;
};

export const replaceInteger = val => {
	return replaceString(/[^0-9]/g, val);
};

export const emptyNumberFormat = val => {
	return val === '' ? '0' : val;
};

export const numberFormat = (type, val) => {
	val = val === '' ? 0 : val;
	if (type && type === 'float') {
		return parseFloat(val);
	} else {
		return parseInt(val);
	}
};

export const moneyFormat = val => {
	let str = '';
	if (/\./.test(val)) {
		let array = val.split('.');
		str = array[0];
		if (array[1].length > 2) {
			str = str + '.' + array[1].substr(0, 3);
		} else {
			str = str + '.' + array[1];
		}
	}
	return str;
};

export const getDateNow = () => {
	let now = new Date();
	let year = now.getFullYear(); // 获取年份
	let month = now.getMonth() + 1; // 获取月份（注意：月份是从 0 开始的，所以需要加 1）
	if (month < 10) {
		month = '0' + month;
	}
	let day = now.getDate();
	return timestampInit(year + '-' + month + '-' + day);
};

export const timestampInit = date => {
	return Date.parse(date);
};

export const checkTimeExpired = (data, status, dateNow) => {
	if (!data || data === '' || dateNow <= timestampInit(data) || status > 4) {
		return false;
	}
	return true;
};

export const getSendAddress = data => {
	return data['send_province_name'] + data['send_city_name'] + data['send_county_name'] + data['send_address'];
};

export const getEndAddress = data => {
	return data['end_province_name'] + data['end_city_name'] + data['end_county_name'] + data['end_address'];
};
