<template>
	<div class='cu-aside'>
		<el-scrollbar height='100%'>
			<div class='padding-5'>
				<el-menu
					:default-active='page'
					class='el-menu-vertical-demo'
					@select='clickSelect'
					active-text-color='#ffffff'
					text-color='#ffffff'
					:unique-opened='true'
				>
					<el-sub-menu :index="'/'+item.path" v-for='(item,index) in action' :key='index'>
						<template #title>
							<i class='iconfont margin-right-5' :class='item.icon'></i>
							<span class='text-white'>{{ item.title }}</span>
						</template>
						<el-menu-item v-for='(child,chx) in item.children' :key='chx'
									  :index="'/'+child.path"
						>
							<span class='text-13'>{{ child.title }}</span>
						</el-menu-item>
					</el-sub-menu>
				</el-menu>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import { listToLevel } from '@/utils/common';
import { openPage } from '@/utils/menu';

export default {
	name: 'cu-aside',
	computed: {
		action() {
			return listToLevel({
				list: this.$store.getters['basic/get_action'],
				keys: 'menu_id',
				pid: '0'
			});
		},
		actions() {
			return this.$store.getters['basic/get_action'];
		},
		page() {
			return this.$store.getters['basic/get_page'];
		}
	},
	methods: {
		clickSelect(index, indexPath) {
			let select = indexPath[indexPath.length - 1];
			let page = this.actions.find(item => '/' + item['path'] === select);
			openPage(page);
		}
	}
};
</script>

<style lang='scss' scoped>
@import "../../assets/layout";
</style>