const state = {
	user: null,
	loading: false,
	action: null,
	pageList: [],
	page: '',
	cityList: [],
	regionList: []
};
const mutations = {
	set_user(state, data) {
		if (!data) {
			Object.keys(state).forEach(keys => {
				if (['page'].includes(keys)) {
					state[keys] = '';
				} else if (['pageList', 'cityList', 'regionList'].includes(keys)) {
					state[keys] = [];
				} else if (['loading'].includes(keys)) {
					state[keys] = false;
				} else {
					state[keys] = null;
				}
			});
		} else {
			state.user = data;
		}
	},
	set_loading(state, data) {
		state.loading = data;
	},
	set_action(state, data) {
		state.action = data;
	},
	set_pageList(state, data) {
		state.pageList = data;
	},
	set_page(state, data) {
		state.page = data;
	},
	set_city(state, data) {
		state.cityList = data;
	},
	set_region(state, data) {
		state.regionList = data;
	}
};
const actions = {
	up_city({ commit }, data) {
		commit('set_city', data);
	},
	up_region({ commit }, data) {
		commit('set_region', data);
	},
	up_user({ commit }, data) {
		commit('set_user', data);
	},
	up_loading({ commit }, data) {
		commit('set_loading', data);
	},
	up_action({ commit }, data) {
		commit('set_action', data);
	},
	up_pageList({ commit }, data) {
		commit('set_pageList', data);
	},
	up_page({ commit }, data) {
		commit('set_page', data);
	}
};
const getters = {
	get_city(state) {
		return state.cityList;
	},
	get_region(state) {
		return state.regionList;
	},
	get_user(state) {
		return state.user;
	},
	get_loading(state) {
		return state.loading;
	},
	get_action(state) {
		return state.action ?? [];
	},
	get_pageList(state) {
		return state.pageList;
	},
	get_page(state) {
		return state.page;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
