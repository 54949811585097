<template>
  <div>
    <el-dialog v-model="options.show"
               append-to-body
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :width="options.width ?? '50%'"
               :title="options.title ?? '弹窗标题'"
               @close="handleClick('close')"
               :top="options.top ?? '60px'"
               :lock-scroll="true"
    >
      <template v-if="options.header ?? false" #header>
        <slot name="header"></slot>
      </template>
      <slot></slot>
      <template v-if="options.footer ?? true" #footer>
        <div class="dialog-footer flex justify-between align-center">
          <div>
            <slot name="foot"></slot>
          </div>
          <div class="flex align-center">
            <el-button size="small" @click="handleClick('close')">{{ options.cancelText ?? '取消' }}</el-button>
            <el-button size="small" v-loading="options.loading ?? false" type="primary" @click="handleClick('confirm')">
              {{ options.confirmText ?? '确定' }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cu-dialog",
  props: {
    options: {
      type:Object,
      default: () => {
        return {
          show: false,
          cancelText: '取消',
          confirmText: '确定',
        }
      }
    }
  },
  methods: {
    handleClick(type) {
      this.$emit(type)
    }
  }
}
</script>

<style scoped>

</style>