import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/views/layout/index';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store/index';
import { updatePage } from '@/utils/menu';
import { listToLevel } from '@/utils/common';

NProgress.configure({ showSpinner: false });

const defaultPage = ['/login', '/home', '/404', '/notFound'];

const routes = [
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登陆',
			hidden: true
		},
		component: () => import('@/views/system/Login.vue')
	},
	{
		path: '/',
		name: 'layout',
		redirect: '/home',
		component: Layout,
		children: [
			{
				path: '/home',
				name: 'home',
				meta: {
					title: '首页',
					hidden: true
				},
				component: () => import('@/views/home/HomeView.vue')
			},
			{
				path: '/notFound',
				name: 'notFound',
				meta: {
					title: 'notFound',
					hidden: false
				},
				component: () => import('@/views/system/NotFound')
			},
			{
				path: '/roles/set',
				name: 'roles-set',
				meta: {
					title: '角色管理',
					hidden: false
				},
				component: () => import('@/views/system/RolesSet')
			},
			{
				path: '/menu/set',
				name: 'menu-set',
				meta: {
					title: '菜单管理',
					hidden: false
				},
				component: () => import('@/views/system/MenuSet')
			},
			{
				path: '/admin/list',
				name: 'admin-list',
				meta: {
					title: '管理员列表',
					hidden: false
				},
				component: () => import('@/views/system/admin-list')
			},
			{
				path: '/company/expense',
				name: 'company-expense',
				meta: {
					title: '费用中心',
					hidden: false
				},
				component: () => import('@/views/system/company-expense')
			},
			{
				path: '/supplier/list',
				name: 'supplier-list',
				meta: {
					title: '企业列表',
					hidden: false
				},
				component: () => import('@/views/custom/supplier-list')
			},
			{
				path: '/custom/list',
				name: 'custom-list',
				meta: {
					title: '客户列表',
					hidden: false
				},
				component: () => import('@/views/custom/custom-list')
			},
			{
				path: '/custom/price',
				name: 'custom-price',
				meta: {
					title: '客户价格',
					hidden: false
				},
				component: () => import('@/views/custom/custom-price')
			},
			{
				path: '/driver/list',
				name: 'driver-list',
				meta: {
					title: '司机管理',
					hidden: false,
					keepAlive: true
				},
				component: () => import('@/views/driver/driver-list')
			},
			{
				path: '/order/list',
				name: 'order-list',
				meta: {
					title: '订单列表',
					hidden: false
				},
				component: () => import('@/views/order/order-list')
			},
			{
				path: '/line/list',
				name: 'line-list',
				meta: {
					title: '零担线路',
					hidden: false
				},
				component: () => import('@/views/order/line-list')
			},
			{
				path: '/order/sales',
				name: 'order-sales',
				meta: {
					title: '我的订单',
					hidden: false
				},
				component: () => import('@/views/order/order-sales')
			},
			{
				path: '/expense/custom',
				name: 'expense-custom',
				meta: {
					title: '客户对账',
					hidden: false
				},
				component: () => import('@/views/order/expense-custom')
			},
			{
				path: '/plat/expense/po',
				name: 'plat-expense-po',
				meta: {
					title: '付承运费用',
					hidden: false
				},
				component: () => import('@/views/order/plat-expense-po')
			},
			{
				path: '/plat/expense/so',
				name: 'plat-expense-so',
				meta: {
					title: '收开单费用',
					hidden: false
				},
				component: () => import('@/views/order/plat-expense-so')
			},
			{
				path: '/order/change',
				name: 'order-change',
				meta: {
					title: '订单修改',
					hidden: false,
					keepAlive: true
				},
				component: () => import('@/views/order/order-change')
			},
			{
				path: '/order/change-review',
				name: 'order-change-review',
				meta: {
					title: '改单审核',
					hidden: false
				},
				component: () => import('@/views/order/order-change-review')
			},
			{
				path: '/banner/list',
				name: 'banner-list',
				meta: {
					title: '轮播图管理',
					hidden: false
				},
				component: () => import('@/views/system/banner-list')
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	NProgress.start();

	//在页面加载时读取sessionStorage里的状态信息
	if (window.sessionStorage.getItem('store')) {
		store.replaceState(Object.assign({}, JSON.parse(sessionStorage.getItem('store'))));
		window.sessionStorage.removeItem('store');
	}

	//检测是否登陆
	let user = store.getters['basic/get_user'];
	if (!user && to.path !== '/login') {
		next({
			path: '/login'
		});
	} else {
		if (to.path === '/login') {
			store.dispatch('basic/up_user', null).then(r => r);
		}

		//动态路由注入
		let action = store.getters['basic/get_action'];
		//检测权限
		let page = action.find(item => '/' + item['path'] === to.path);
		let isDefault = defaultPage.includes(to.path);
		if (to.matched.length === 0 || (!page && !isDefault)) {
			next({
				path: '/notFound'
			});
		} else {
			next();
		}
		updatePage(to).then(r => r);
	}
	// document.title = to.meta.title ? '福创龙吟系统' + to.meta.title : '福创龙吟系统';
	document.title = '福创-龙吟A6';
});

router.afterEach(() => {
	NProgress.done();
});

const getMapRouters = list => {
	let storeRouters = listToLevel({ list: list, keys: 'menu_id' });
	let data = [];
	storeRouters.forEach(row => {
		row.children.forEach(item => {
			data.push({
				path: item.path,
				name: item.title,
				meta: {
					title: item.title,
					hidden: false
				},
				component: () => import(`../views/${item.component}`)
			});
		});
	});
	return [
		{
			path: '/',
			name: 'layout2',
			component: Layout,
			children: data
		}
	];
};

export default router;
