import http from '@/utils/http';

// const api = {};
//
// const files = require.context("./", true, /\.js$/);
//
// files.keys().forEach((item) => {
//     if (item !== "./index.js") {
//         let moduleName = item.split("/").pop().replace(/\.js$/, "");
//         let moduleFile = files(item);
//         api[moduleName] = moduleFile.default || moduleFile;
//     }
// });

export default {
	basic: {
		exportExcel: data => {
			return http({ url: '/api/v3/basic/exportExcel', data, tip: true }).then(r => r);
		},
		uploadImage: data => {
			return http({ url: '/api/v3/basic/uploadImage', data }).then(r => r);
		},
		removeImage: data => {
			return http({ url: '/api/v3/basic/removeImage', data }).then(r => r);
		}
	},
	company: {
		list: data => {
			return http({ url: '/api/v3/company/list', data }).then(r => r);
		},
		save: data => {
			return http({ url: '/api/v3/company/save', data, tip: true }).then(r => r);
		},
		remove: data => {
			return http({ url: '/api/v3/company/remove', data, tip: true }).then(r => r);
		},
		getSubAll: () => {
			return http({ url: '/api/v3/company/getSubAll' }).then(r => r);
		}
	},
	custom: {
		list: data => {
			return http({ url: '/api/v3/custom/list', data }).then(r => r);
		},
		remove: data => {
			return http({ url: '/api/v3/custom/remove', data, tip: true }).then(r => r);
		},
		save: data => {
			return http({ url: '/api/v3/custom/save', data, tip: true }).then(r => r);
		},
		searchName: data => {
			return http({ url: '/api/v3/custom/searchName', data }).then(r => r);
		},
		getListAll: data => {
			return http({ url: '/api/v3/custom/getListAll', data }).then(r => r);
		},
		getCompanyCustomAll: data => {
			return http({ url: '/api/v3/custom/getCompanyCustomAll', data }).then(r => r);
		}
	},
	price: {
		save: data => {
			return http({ url: '/api/v3/price/save', data, tip: true }).then(r => r);
		},
		list: data => {
			return http({ url: '/api/v3/price/list', data }).then(r => r);
		},
		remove: data => {
			return http({ url: '/api/v3/price/remove', data, tip: true }).then(r => r);
		},
		findPrice: data => {
			return http({ url: '/api/v3/price/findPrice', data }).then(r => r);
		}
	},
	menu: {
		list: () => {
			return http({ url: '/api/v3/menu/list' }).then(r => r);
		},
		save: data => {
			return http({ url: '/api/v3/menu/save', data, tip: true }).then(r => r);
		},
		delete: data => {
			return http({ url: '/api/v3/menu/delete', data, tip: true }).then(r => r);
		},
		topList: () => {
			return http({ url: '/api/v3/menu/getTopList' }).then(r => r);
		},
		getAll: data => {
			return http({ url: '/api/v3/menu/getAll', data }).then(r => r);
		}
	},
	order: {
		getListByPlat: data => {
			return http({ url: '/api/v3/order/getListByPlat', data }).then(r => r);
		},
		getDetail: data => {
			return http({ url: '/api/v3/order/getDetail', data }).then(r => r);
		},
		pcCreate: data => {
			return http({ url: '/api/v3/order/pcCreate', data, tip: true }).then(r => r);
		},
		getListByPlatMy: data => {
			return http({ url: '/api/v3/order/getListByPlatMy', data }).then(r => r);
		},
		getListBySend: data => {
			return http({ url: '/api/v3/order/getListBySend', data }).then(r => r);
		},
		getListBySupplier: data => {
			return http({ url: '/api/v3/order/getListBySupplier', data }).then(r => r);
		},
		setConfirm: data => {
			return http({ url: '/api/v3/order/setConfirm', data, tip: true }).then(r => r);
		},
		setToSupplier: data => {
			return http({ url: '/api/v3/order/setToSupplier', data, tip: true }).then(r => r);
		},
		setPick: data => {
			return http({ url: '/api/v3/order/setPick', data, tip: true }).then(r => r);
		},
		setSender: data => {
			return http({ url: '/api/v3/order/setSender', data, tip: true }).then(r => r);
		},
		setSend: data => {
			return http({ url: '/api/v3/order/setSend', data, tip: true }).then(r => r);
		},
		setGave: data => {
			return http({ url: '/api/v3/order/setGave', data, tip: true }).then(r => r);
		},
		setBackImage: data => {
			return http({ url: '/api/v3/order/setBackImage', data, tip: true }).then(r => r);
		},
		setReject: data => {
			return http({ url: '/api/v3/order/setReject', data, tip: true }).then(r => r);
		},
		orderCountByCreate: () => {
			return http({ url: '/api/v3/order/orderCountByCreate' }).then(r => r);
		},
		orderCountBySupplier: () => {
			return http({ url: '/api/v3/order/orderCountBySupplier' }).then(r => r);
		},
		orderCountByCustom: data => {
			return http({ url: '/api/v3/order/orderCountByCustom', data }).then(r => r);
		},
		orderCountByCity: data => {
			return http({ url: '/api/v3/order/orderCountByCity', data }).then(r => r);
		},
		orderChange: data => {
			return http({ url: '/api/v3/order/orderChange', data, tip: true }).then(r => r);
		},
		orderChangeReviewList: data => {
			return http({ url: '/api/v3/order/orderChangeReviewList', data }).then(r => r);
		},
		orderChangeReview: data => {
			return http({ url: '/api/v3/order/orderChangeReview', data, tip: true }).then(r => r);
		}
	},
	expense: {
		getListByCustom: data => {
			return http({ url: '/api/v3/expense/getListByCustom', data }).then(r => r);
		},
		setCustomPay: data => {
			return http({ url: '/api/v3/expense/setCustomPay', data, tip: true }).then(r => r);
		},
		getPlatPoExpense: data => {
			return http({ url: '/api/v3/expense/getPlatPoExpense', data }).then(r => r);
		},
		setSupplierPay: data => {
			return http({ url: '/api/v3/expense/setSupplierPay', data, tip: true }).then(r => r);
		},
		getSupplierSoExpense: data => {
			return http({ url: '/api/v3/expense/getSupplierSoExpense', data }).then(r => r);
		},
		getPlatSoExpense: data => {
			return http({ url: '/api/v3/expense/getPlatSoExpense', data }).then(r => r);
		},
		getCreatePoExpense: data => {
			return http({ url: '/api/v3/expense/getCreatePoExpense', data }).then(r => r);
		}
	},
	region: {
		getListAll: () => {
			return http({ url: '/api/v3/region/getListAll' }).then(r => r);
		},
		getCityList: () => {
			return http({ url: '/api/v3/region/getCityList' }).then(r => r);
		}
	},
	roles: {
		save: data => {
			return http({ url: '/api/v3/roles/save', data, tip: true }).then(r => r);
		},
		remove: data => {
			return http({ url: '/api/v3/roles/remove', data, tip: true }).then(r => r);
		},
		list: data => {
			return http({ url: '/api/v3/roles/list', data }).then(r => r);
		},
		getListAll: () => {
			return http({ url: '/api/v3/roles/getListAll' }).then(r => r);
		}
	},
	user: {
		login: data => {
			return http({ url: '/api/v3/user/login', data }).then(r => r);
		},
		changePassword: data => {
			return http({ url: '/api/v3/user/updatePassword', data, tip: true }).then(r => r);
		},
		list: data => {
			//本公司员工
			return http({ url: '/api/v3/user/list', data }).then(r => r);
		},
		remove: data => {
			return http({ url: '/api/v3/user/remove', data, tip: true }).then(r => r);
		},
		save: data => {
			return http({ url: '/api/v3/user/save', data, tip: true }).then(r => r);
		},
		getListAll: () => {
			return http({ url: '/api/v3/user/getListAll' }).then(r => r);
		}
	},
	banner: {
		create: data => {
			return http({ url: '/api/v3/banner/create', data, tip: true }).then(r => r);
		},
		list: data => {
			return http({ url: '/api/v3/banner/list', data }).then(r => r);
		},
		delete: data => {
			return http({ url: '/api/v3/banner/delete', data, tip: true }).then(r => r);
		}
	}
};
