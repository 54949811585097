<template>
	<router-view />
</template>

<script>
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的
const debounce = (fn, delay) => {
	let timer = null;
	return function() {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function() {
			fn.apply(context, args);
		}, delay);
	};
};
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};

export default {
	created() {
		//在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', async () => {
			sessionStorage.setItem('store', JSON.stringify(this.$store.state));
		});
	}
};
</script>

<style lang='scss'>
@import "assets/iconfont.css";
@import "assets/main.css";
@import "assets/layout";
@import "assets/element.css";

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//.loading {
//	width: 100%;
//	height: 100%;
//	background: rgba(255, 255, 255, .6);
//	position: fixed;
//	top: 0;
//	left: 0;
//	z-index: 999999;
//	display: flex;
//	justify-content: center;
//	align-items: center;
//	color: #e54d42;
//	font-size: 30px;
//	@keyframes animal {
//		0% {
//			transform: rotate(0deg);
//			-ms-transform: rotate(0deg);
//			-webkit-transform: rotate(0deg);
//		}
//		100% {
//			transform: rotate(360deg);
//			-ms-transform: rotate(360deg);
//			-webkit-transform: rotate(360deg);
//		}
//	}
//
//	i {
//		color: #e54d42;
//		font-size: 40px;
//		animation: animal 1.4s infinite linear;
//		transform-origin: center center;
//	}
//}

</style>
